// .login-form-page {
//   .title-row {
//     display: flex;
//     justify-content: center;
//     margin-top: 20px;
//     .logo {
//       width: 130px;
//     }
//   }

//   .login-form-ctn {
//     margin-top: 6rem;
//     min-width: 360px;
//     max-height: 277px;
//     display: flex;
//     padding: 10px 40px;
//     flex-direction: column;
//     .page-title {
//       display: flex;
//       justify-content: center;
//       font-size: 24px;
//       line-height: 24px;
//       color: var(--ion-color-secondary-contrast);
//       font-family: Bebas Neue;
//     }

//     .usr-input,
//     .code-input,
//     .pwd-input {
//       display: inline-grid;
//       .input-labell {
//         color: #fff !important;
//       }
//     }
//     .pwd-input {
//       margin-top: 13px;
//     }

//     .form-control {
//       margin: 10px 0;
//       .MuiFormControl-root {
//         background: #1f2e52 !important;
//         .MuiInputBase-root {
//           background: #1f2e52 !important;
//           color: #fff !important;
//         }
//       }
//     }

//     .MuiInputBase-input {
//       font-size: 16px;
//       font-family: Inter;
//     }

//     .MuiIconButton-root {
//       padding: 0;
//       opacity: 0.4;
//     }

//     .login-err-msg {
//       color: red;
//       margin-top: 20px;
//       text-align: center;
//     }
//     // .usr-input {
//     //   margin-top: 60px;
//     // }
//     // .pwd-input,
//     // .code-input {
//     //   margin-top: 40px;
//     // }

//     .user-name,
//     .pwd-field {
//       max-width: 341px;
//       height: 48px;
//       .input-label {
//         color: #fff !important;
//       }
//     }

//     .login-form-btn {
//       margin-top: 20px !important;
//       max-width: 341px;
//       height: 48px;
//       font-family: Inter;
//       font-weight: 500;
//       font-size: 16px;
//       line-height: 19px;
//     }

//     .forgot-pwd-link {
//       // margin-top: 16px;
//       margin-bottom: 20px;
//       font-size: 16px;
//       color: var(--ion-color-secondary-contrast);
//       text-align: center;
//       cursor: pointer;
//     }

//     .bottom-link {
//       margin-top: 24px;
//       margin-bottom: 6px;
//       font-size: 16px;
//       color: var(--ion-color-secondary-contrast);
//       text-align: center;

//       .register-link {
//         color: var(--ion-color-secondary-contrast);
//         cursor: pointer;
//       }
//     }
//   }
// }

// // @media (min-width: 350px) and (max-width: 420px) {
// //   .user-name,
// //   .pwd-field,
// //   .login-form-btn {
// //     max-width: 298px !important;
// //   }
// // }

// // @media (min-width: 220px) and (max-width: 349px) {
// //   .user-name,
// //   .pwd-field,
// //   .login-form-btn {
// //     max-width: 282px !important;
// //   }
// // }

// @media screen and (max-width: 720px) {
//   .login-form-page {
//     width: 100%;
//     padding: 0px;
//     .title-row {
//       display: flex;
//       justify-content: center;
//       margin-top: 30px;

//       .logo {
//         width: 130px;
//       }
//     }

//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 7rem;
//       padding: 10px 18px !important;
//       margin-left: 15px;
//       .user-name,
//       .pwd-field,
//       .login-form-btn {
//         max-width: 274px;
//       }
//     }
//   }
// }
// @media only screen and (device-width: 360px) {
//   .login-form-page {
//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 5rem;
//       padding: 10px 18px !important;
//       margin-left: 0px;
//     }
//   }
// }
// @media only screen and (device-width: 411px) and (device-height: 823px) {
//   .login-form-page {
//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 7rem;
//       padding: 10px 18px !important;
//     }
//   }
// }
// @media only screen and (device-width: 375px) {
//   .login-form-page {
//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 5rem;
//       padding: 10px 18px !important;
//       margin-left: 0px;
//     }
//   }
// }
// @media only screen and (device-width: 375px) and (device-height: 667px) {
//   .login-form-page {
//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 6rem;
//       padding: 10px 18px !important;
//     }
//   }
// }
// @media only screen and (max-device-width: 320px) {
//   .login-form-page {
//     .title-row {
//       display: flex;
//       justify-content: center;
//       margin-top: 35px;

//       .logo {
//         width: 130px;
//       }
//     }

//     .login-form-ctn {
//       min-width: 250px !important;
//       max-width: 250px !important;
//       margin-top: 4rem;
//       padding: 10px 18px !important;

//       .page-title {
//         font-size: 16px;
//         line-height: 16px;
//       }

//       .MuiInputBase-input {
//         font-size: 13px;
//         font-family: Inter;
//       }

//       .user-name,
//       .pwd-field {
//         height: 38px;
//       }
//       // .user-name,
//       // .pwd-field,
//       // .login-form-btn {
//       //   max-width: 250px;
//       // }

//       .login-form-btn {
//         height: 30px;
//         font-size: 13px;
//       }
//     }
//   }
// }

.login-form-page {
  .title-row {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .logo {
      width: 130px;
    }
  }

  .login-form-ctn {
    margin-top: 4rem;
    min-width: 360px;
    max-height: 415px;
    display: flex;
    padding: 25px 35px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.09);
    border-radius: 4px;
    .card-title {
      font-size: 22px;
      color: var(--ion-color-primary);
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
    .page-title {
      display: flex;
      justify-content: center;
      font-size: 24px;
      line-height: 24px;
      color: var(--ion-color-secondary-contrast);
      font-family: Bebas Neue;
    }

    .usr-input,
    .code-input,
    .pwd-input {
      display: inline-grid;
      .input-labell {
        color: #fff !important;
      }
    }
    .pwd-input {
      margin-top: 13px;
    }

    .form-control {
      margin: 10px 0;
      .MuiFormControl-root {
        background: #1f2e52 !important;
        .MuiInputBase-root {
          background: #1f2e52 !important;
          color: #fff !important;
        }
      }
    }

    .MuiInputBase-input {
      font-size: 16px;
      font-family: Inter;
    }

    .MuiIconButton-root {
      padding: 0;
      opacity: 0.4;
    }

    .login-err-msg {
      color: red;
      margin-top: 20px;
      text-align: center;
    }
    // .usr-input {
    //   margin-top: 60px;
    // }
    // .pwd-input,
    // .code-input {
    //   margin-top: 40px;
    // }

    .user-name,
    .pwd-field {
      max-width: 341px;
      height: 48px;
      .input-label {
        color: #fff !important;
      }
    }

    .login-form-btn {
      margin-top: 20px !important;
      max-width: 341px;
      height: 48px;
      font-size: 16px;
      // line-height: 19px;
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast) !important;
      font-weight: bold;
    }

    .forgot-pwd-link {
      // margin-top: 16px;
      margin-bottom: 20px;
      font-size: 16px;
      color: var(--ion-color-secondary-contrast);
      text-align: center;
      cursor: pointer;
    }

    .bottom-link {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 6px;
      font-size: 16px;
      color: var(--ion-color-secondary-contrast);
      text-align: center;
      text-decoration: underline;
      cursor: pointer;

      .register-link {
        color: var(--ion-color-secondary-contrast);
        cursor: pointer;
      }
    }
  }
}

// @media (min-width: 350px) and (max-width: 420px) {
//   .user-name,
//   .pwd-field,
//   .login-form-btn {
//     max-width: 298px !important;
//   }
// }

// @media (min-width: 220px) and (max-width: 349px) {
//   .user-name,
//   .pwd-field,
//   .login-form-btn {
//     max-width: 282px !important;
//   }
// }

@media screen and (max-width: 720px) {
  .login-form-page {
    width: 100%;
    padding: 0px;
    .title-row {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .logo {
        width: 130px;
      }
    }

    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
      margin-left: 15px;
      .user-name,
      .pwd-field,
      .login-form-btn {
        max-width: 274px;
      }
    }
  }
}
@media only screen and (device-width: 360px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
      margin-left: 0px;
    }
  }
}
@media only screen and (device-width: 411px) and (device-height: 823px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 10rem;
      padding: 10px 18px !important;
    }
  }
}
@media only screen and (device-width: 375px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
      margin-left: 0px;
    }
  }
}
@media only screen and (device-width: 375px) and (device-height: 667px) {
  .login-form-page {
    .login-form-ctn {
      min-width: 314px !important;
      max-width: 314px !important;
      margin-top: 12rem;
      padding: 10px 18px !important;
    }
  }
}
@media only screen and (max-device-width: 320px) {
  .login-form-page {
    .title-row {
      display: flex;
      justify-content: center;
      margin-top: 35px;

      .logo {
        width: 130px;
      }
    }

    .login-form-ctn {
      min-width: 250px !important;
      max-width: 250px !important;
      margin-top: 10rem;
      padding: 10px 18px !important;

      .page-title {
        font-size: 16px;
        line-height: 16px;
      }

      .MuiInputBase-input {
        font-size: 13px;
        font-family: Inter;
      }

      .user-name,
      .pwd-field {
        height: 38px;
      }
      // .user-name,
      // .pwd-field,
      // .login-form-btn {
      //   max-width: 250px;
      // }

      .login-form-btn {
        height: 30px;
        font-size: 13px;
      }
    }
  }
}
